/* eslint-disable */
export default function useDateFormat(date: Date, format = 'DD.MM.YYYY'): string {
    const day = date.getDate()
    const month = date.getMonth()
    const week = useDateWeek(date)
    const year = date.getFullYear()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    const milliseconds = date.getMilliseconds()
    const h = hour % 12
    const hh = twoDigitPad(h)
    const HH = twoDigitPad(hour)
    const mm = twoDigitPad(minute)
    const ss = twoDigitPad(second)
    const aaa = hour < 12 ? 'AM' : 'PM'
    const dd = twoDigitPad(day)
    const M = month + 1
    const MM = twoDigitPad(M)
    const w = week
    const ww = twoDigitPad(week)
    const yyyy = `${year}`
    const yy = yyyy.substr(2, 2)
    // Checks to see if month name will be used
    return format
        .replace('hh', hh)
        .replace('h', `${h}`)
        .replace('HH', HH)
        .replace('H', `${hour}`)
        .replace('mm', mm)
        .replace('m', `${minute}`)
        .replace('ss', ss)
        .replace('s', `${second}`)
        .replace('S', `${milliseconds}`)
        .replace('DD', dd)
        .replace('D', `${day}`)
        .replace('YYYY', yyyy)
        .replace('YY', yy)
        .replace('ww', ww)
        .replace('w', `${w}`)
        .replace('aaa', aaa)
        .replace('MM', MM)
        .replace('M', `${M}`)
}

function twoDigitPad(num: number): string {
    return num < 10 ? `0${num}` : `${num}`
}
